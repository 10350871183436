import { render, staticRenderFns } from "./card2023.vue?vue&type=template&id=34d8a80c&scoped=true&"
import script from "./card2023.vue?vue&type=script&lang=js&"
export * from "./card2023.vue?vue&type=script&lang=js&"
import style0 from "./card2023.vue?vue&type=style&index=0&id=34d8a80c&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34d8a80c",
  null
  
)

export default component.exports