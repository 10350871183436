import request from '@/utils/request'


///api/Card/Card2023
export function  CreatCard2023(mid) {
    return request({
      url: `/api/Card/Card2023?memberid=${mid}`
    })
}


export function  getCard2023List(mid) {
    return request({
      url: `/api/Card/Card2023List?memberid=${mid}`
    })
}