<template>
    <div class="container">
        <van-nav-bar :title="title" left-arrow @click-left="$router.back()">
        </van-nav-bar>
        <div>
            <van-cell-group>
                <van-field v-model="para" label="A3客户编号" placeholder="A3客户编号" readonly />
            </van-cell-group>
            <!-- <van-button type="info" size="large" block @click="createPoster">生成海报</van-button> -->
        </div>


        <div v-if="posterList.length > 0">
            <van-notice-bar color="#1989fa" background="#ecf9ff" left-icon="info-o">
                长按图片可下载或分享
            </van-notice-bar>

            <van-cell-group>
                <!-- art_id有可能是大数字 超过一定长度会转成 BigNumber  要toString一下 -->
                <van-cell v-for="item in posterList" :key="item.id.toString()">
                    <div style="display: table-cell; vertical-align: middle" class="zntitle van-multi-ellipsis--l3">
                        <h3 style="display: inline-block">卡号：{{ item.num }}</h3>
                    </div>
                    <div class="acontent">
                        <van-image lazy-load class="w100" fit="contain" width="50%" :src="item.qrPrviewImage" />
                    </div>
                </van-cell>
            </van-cell-group>
            
        </div>
        <div v-else  style="text-align: center;">
            暂无数据
        </div>
    </div>
</template>
<script>

//import { Toast } from "vant";
import { CreatCard2023, getCard2023List } from "@/api/card";


//const key = "zn-history";
export default {
    name: "question-detail-type",
    data() {
        return {
            questionnaireId: "",
            scroll: 0,
            activeName: "a",
                        posterList: [],
            title: "客户生成龙卡",
            
            para: "",
            page: {
                current: 1,
                pageSize: 10,
                sorterType: 0,
                filterData: {
                    title: "",
                    questionnaireId: "",
                    para: ""
                },
            },
        };
    },
    components: {
    },
    activated() { },
    created() {
        this.initData();
    },
    updated() {
        this.addpreview();
    },
    watch: {
        $route(to, from) {
            //监听路由是否变化
            if (to.path == "/question/card2023") {
                if (to.query != from.query) {
                    this.initData();
                }
            }
        },
    },

    methods: {
        async initData() {
            this.questionnaireId = this.$route.query.id;
            if (this.$route.query.a3code) {
                this.para = this.$route.query.a3code;
            }
            

            if (this.para) {
                await CreatCard2023(this.para); //({ ...this.page })
                let aresult = await getCard2023List(this.para);
                this.posterList=aresult.data.data;

            }
        },

        //加图片预览
        addpreview() {
            var listdiv = document.getElementsByClassName("acontent");
            listdiv.forEach((acontentdiv) => {
                var imgs = acontentdiv.getElementsByTagName("img");

                imgs.forEach((element) => {
                    element.setAttribute("preview", this.questionnaireId);
                });
            });
            this.$previewRefresh();
        },

    },
};
</script>
  
<style lang="less" scoped>
.zntitle {
    height: 30px;
}

.container {
    height: 100%;
    overflow-y: auto;
    margin-bottom: 50px;

    /deep/.van-tabs {
        height: 100%;
    }

    /deep/.van-tabs__content {
        height: 100%;
    }
}

.van-tabs {
    height: 100%;
    display: flex;
    flex-direction: column;

    /deep/ .van-tabs__wrap {
        height: 36px;
        padding-right: 36px;

        .van-tab {
            line-height: 36px;
        }

        .van-tabs__line {
            background-color: #3296fa;
            height: 2px;
        }
    }

    /deep/ .van-tabs__content {
        flex: 1;
        overflow: hidden;
    }

    /deep/ .van-tab__pane {
        height: 100%;

        .scroll-wrapper {
            height: 100%;
            overflow-y: auto;
        }
    }
}

.tabcontent {
    height: 100%;
    margin-bottom: 138px;
}
</style>
  